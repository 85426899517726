<script>
import { wpService } from "@/services/wp";
import { mapGetters } from "vuex";
import _find from "lodash/find";
export default {
  name: "user",

  metaInfo() {
    const title = this.pageTitle;
    return {
      title,
    };
  },

  components: {
    singleListing: () =>
      import(
        /* webpackChunkName: "single-listing" */ "@/components/single-listing.vue"
      ),
    appBack: () =>
      import(/* webpackChunkName: "app-back" */ "@/components/app-back"),
    imageSwapper: () =>
      import(
        /* webpackChunkName: "image-swapper" */ "@/components/image-swapper.vue"
      ),
  },

  data() {
    return {
      user_id: Number(this.$route.params.user_id),
      info: null,
      ready: false,
      listings: null,
      selectedTrades: [],
    };
  },

  computed: {
    isSingleListing() {
      let ltg_id = Number(this.$route.params.ltg_id) || 0;
      if (ltg_id) {
        return {
          ltg_id: ltg_id,
          data: _find(this.listings, { ltg_id: ltg_id }) || null,
        };
      }
      return null;
    },
    pageTitle() {
      return this.info ? this.info.user_name_full : undefined;
    },
    ...mapGetters("config", ["temp_trades", "get_category", "get_location"]),
  },

  created() {
    // console.log("created");
    if (this.info) return;
    this.fetchInfo().then(() => {
      // console.log("now you can fetch user listings...");
      this.fetchListings();
    });
  },

  methods: {
    exitSingleListing() {
      this.$router.push({
        name: "user",
        params: {
          user_id: this.user_id,
        },
      });
    },
    fetchInfo() {
      this.ready = false;
      this.info = null;
      return wpService.users
        .getUserInfo(this.user_id)
        .then(response => {
          this.info = response;
          this.ready = true;
        })
        .catch(() => {
          this.$router.replace({ name: "404" });
        });
    },
    fetchListings() {
      this.listings = null;
      wpService.users
        .queryUserListings(this.user_id)
        .then(response => {
          this.listings = response.entries;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getYear() {
      return new Date(this.info.user_registered * 1000).getFullYear();
    },
    goSingle(entry) {
      this.$router.push({
        name: "user-listings-single",
        params: {
          ltg_id: entry.ltg_id,
        },
      });
    },
  },
};
</script>

<template>
  <sitio-layout>
    <b-loading :active="!ready"></b-loading>
    <div v-if="ready" class="app__view layout" :class="{ isSingleListing }">
      <div class="user__sidebar">
        <div class="section header">
          <div class="wrapper">
            <appBack />
            <div class="chip">
              <div class="avatar">
                <img
                  v-if="!info.user_avatar"
                  src="@/assets/avatar-square.svg"
                />
                <img
                  v-else
                  :src="info.user_avatar.src"
                  :srcset="info.user_avatar.srcset"
                />
              </div>
              <div class="data">
                <h1 class="nickname">{{ info.user_name_full }}</h1>
                <div class="joined">Ingresó en {{ getYear() }}</div>
                <div class="social">
                  <a href=""><i class="fab fa-facebook"></i></a>
                  <a href=""><i class="fab fa-instagram"></i></a>
                  <a href=""><i class="fab fa-linkedin"></i></a>
                  <a href=""><i class="fab fa-twitter"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section info">
          <div class="wrapper">
            <div class="description" v-if="info.user_biography">
              {{ info.user_biography }}
            </div>
            <div class="description" v-else>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsam ut
              eveniet eos tempore quod, dignissimos, maxime autem temporibus
              itaque debitis in mollitia distinctio odio molestias officia sit
              eius consequuntur. Possimus.
            </div>
            <div class="features">
              <div class="databox address">
                <div class="da__label">Dirección:</div>
                <div class="da__value">
                  Calle Higuemota #9, Edif. Laura Marcelle V, Bella Vista. Santo
                  Domingo. Republica Dominicana
                </div>
              </div>
              <div class="databox phone_fixed">
                <div class="da__label">Teléfono fijo:</div>
                <div class="da__value">
                  <a :href="'tel:+1' + info.user_phone_fixed">{{
                    info.user_phone_fixed | formatPhone
                  }}</a>
                </div>
              </div>
              <div class="databox phone_fixed">
                <div class="da__label">Teléfono móvil:</div>
                <div class="da__value">
                  <a :href="'tel:+1' + info.user_phone_mobile">{{
                    info.user_phone_mobile | formatPhone
                  }}</a>
                </div>
              </div>
              <div class="databox speaks">
                <div class="da__label">Idiomas:</div>
                <div class="da__value">Español, Ingles</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="user__content">
        <section class="section section--listings">
          <div class="wrapper">
            <h3 class="section__title">Anuncios</h3>
            <b-loading v-if="!listings" :active="true" :is-full-page="false" />
            <div v-else-if="listings.length" class="listings__feed">
              <!-- listings go here... -->
              <article
                v-for="entry in listings"
                :key="entry.ltg_id"
                class="entry"
                @click="goSingle(entry)"
              >
                <div class="gallery">
                  <imageSwapper
                    :images="entry.ltg_images"
                    sizes="(min-width: 100em) 16.666666667vw, (min-width: 75em) 20vw, (min-width: 60em) 25vw, (min-width: 46em) 30vw, (min-width: 40em) 40vw, 95vw"
                  />
                </div>
                <div class="entry__content">
                  <div class="category">
                    {{ get_category(entry.ltg_cat).label }}
                  </div>
                  <div class="macros">
                    <div class="da--ltg_room">
                      <span class="da__value">{{
                        entry.ltg_room || "--"
                      }}</span>
                      <span class="da__label" v-if="entry.ltg_room > 1"
                        >Habs.</span
                      >
                      <span class="da__label" v-else>Hab.</span>
                    </div>
                    <div class="da--ltg_bath">
                      <span class="da__value">{{
                        entry.ltg_bath || "--"
                      }}</span>
                      <span class="da__label" v-if="entry.ltg_bath > 1"
                        >Baños</span
                      >
                      <span class="da__label" v-else>Baño</span>
                    </div>
                    <div class="da--ltg_park">
                      <span class="da__value">{{
                        entry.ltg_park || "--"
                      }}</span>
                      <span class="da__label" v-if="entry.ltg_park > 1"
                        >Parqueos</span
                      >
                      <span class="da__label" v-else>Parqueo</span>
                    </div>
                  </div>
                  <div class="address">
                    {{ get_location(entry.ltg_lctn).loc_name_long }}
                  </div>
                  <div class="prices">
                    <div v-if="selectedTrades.length" class="price">
                      tradeKey in selectedTrades => entry[tradeKey]....
                    </div>
                    <template v-else v-for="(data, trade) in temp_trades">
                      <div v-if="entry[trade]" :key="trade" class="price">
                        <div class="price__label">{{ data.label }}:</div>
                        <div class="price__value">
                          <span class="price__currency">USD</span>
                          ${{ entry[trade] | formatMoney(0) }}
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
      </div>
    </div>
    <singleListing
      v-if="isSingleListing"
      :prefill="isSingleListing.data"
      @exit="exitSingleListing"
    />
  </sitio-layout>
</template>

<style lang="scss" scoped>
.app__view {
  padding: 0;
}

.app-back {
  margin-bottom: 1.75rem;
  background-color: transparent;
  color: #fff;
  position: absolute;
  z-index: 1;
  top: 0.75rem;
  left: 0.75rem;
}

.section {
  position: relative;
  padding: 2.5rem 1.75rem;
  .wrapper {
    width: 100%;
    max-width: 64em;
  }
}

.section.info .wrapper > *:not(:last-child) {
  margin-bottom: 1.75rem;
}

.section__title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
}

.header {
  min-height: 12rem;
  display: flex;
  align-items: flex-end;
  width: 100%;
  .avatar {
    margin-bottom: 1.75rem;
    width: 7rem;
    box-shadow: 0 4px 12px 1px rgba(0, 0, 0, 0.08);
    border-radius: 1rem;
    border: 2px solid #fff;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
    }
  }
  .nickname {
    font-weight: bolder;
    font-size: 1.875rem;
    line-height: 1.2;
    text-shadow: 0px 1px rgba(0, 0, 0, 0.08);
  }
  .joined {
    opacity: 0.65;
  }
  .social {
    margin-top: 1rem;
    > * {
      font-size: 1.5rem;
      // padding: 1rem;
      color: #000;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

.user__content {
  background-color: rgb(245, 245, 245);
}

.features {
  .databox > * {
    display: inline-block;
  }
}

.databox {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  .da__label {
    padding-right: 0.5rem;
    font-weight: 600;
  }
}

.section--listings {
  min-height: 20rem;
}

.entry {
  cursor: pointer;
  margin-bottom: 3rem;
  .category {
    font-size: 1.25rem;
    font-weight: bold;
  }
  .macros {
    display: flex;
    margin-bottom: 0.5rem;
    font-size: 0.875em;
    > :not(:first-child) {
      margin-left: 0.5rem;
      border-left: 1px solid #ccc;
      padding-left: 0.5rem;
    }
    .da__value {
      font-weight: bold;
    }
    .da__label:before {
      display: inline;
      content: "\00a0";
    }
  }
  .address {
    margin-bottom: 1.25rem;
  }
  .price {
    .price__label {
      font-size: 0.875em;
      font-weight: 500;
      padding-right: 0.25rem;
      opacity: 0.65;
    }
  }
  .gallery {
    margin-bottom: 1rem;
    .imageSwapper {
      border-radius: 1rem;
      overflow: hidden;
      will-change: transform; /* Fix iOS swipe overflow */
      // -webkit-mask-image: -webkit-radial-gradient(white, black);
      // backface-visibility: hidden;
      // transform: translate3d(0, 0, 0);
    }
  }
}

@media (max-width: 63.9375em) {
  .section {
    .wrapper {
      max-width: 28em;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .entry {
    background-color: #fff;
    box-shadow: 0 4px 12px 1px rgba(0, 0, 0, 0.08);
    border-radius: 1rem;
    .gallery .imageSwapper {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .entry__content {
      padding: 1.75rem;
      padding-top: 0;
    }
    .address {
      font-size: 0.875em;
      opacity: 0.75;
    }
    .price {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media (max-width: 31.1875em) {
  .header {
    text-align: center;
    .avatar {
      margin: 0 auto 1rem;
      border-radius: 50%;
    }
    .nickname {
      font-size: 1.625rem;
    }
  }
  .description,
  .features {
    font-size: 0.9375em;
  }
}

@media (min-width: 64em) {
  .app__view {
    padding: 0;
    display: flex;
    width: 100%;
    overflow: hidden;
    .user__sidebar,
    .user__content {
      overflow: hidden;
      overflow-y: auto;
    }
    .user__sidebar {
      flex: 1 0 20rem;
      border-right: 1px solid #ecf0f4;
      .section {
        display: flex;
        justify-content: flex-end;
        .wrapper {
          width: 100%;
          max-width: 20rem;
        }
      }
    }
    .user__content {
      flex: 0 1 66.666666667%;
    }
  }
  .app-back {
    display: none;
  }
  .header .avatar {
    width: 8rem;
  }
  .entry {
    display: flex;
    .gallery {
      width: 40%;
      max-width: 20rem;
      margin-right: 1.75rem;
    }
    .entry__content {
      padding-top: 0.5rem;
    }
  }
}
</style>
